const appConfig = {
  apiPrefix: process.env.REACT_APP_API_ROUTE,
  authenticatedEntryPath: '/home',
  authenticatedStaffEntryPath: '/home',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '',
  enableMock: false,
  assetUrl: process.env.REACT_APP_ASSET_URL,
};

export default appConfig;
