import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { setUser, initialState } from 'store/auth/userSlice';
import { apiSignIn, apiSignOut } from 'services/AuthService';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import appConfig from 'configs/app.config';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQuery from './useQuery';
import { setLayout } from 'store/theme/themeSlice';
import { THEME_ENUM } from 'constants/theme.constant';

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async ({ username, password }) => {
    try {
      const resp = await apiSignIn({ username, password });
      if (resp.data) {
        const {
          meta: { access_token: token },
        } = resp.data;
        dispatch(onSignInSuccess(token));
        const data = get(resp, 'data.data');
        var role = '';
        if (data) {
          role = get(data, 'role.0');
          const user = {
            avatar: '',
            userName: data.firstname,
            authority:
              role.name === 'snod' || role.name === 'utnod' || role.name === 'cmnod'
                ? ['user']
                : role.name === 'admin'
                ? 'admin'
                : ['reviewer'],
            email: data.email,
          };
          dispatch(
            setUser(
              user || {
                avatar: '',
                userName: 'Anonymous',
                authority: ['USER'],
                email: '',
              },
            ),
          );
        }

        dispatch(setLayout(THEME_ENUM.LAYOUT_TYPE_MODERN));
        const path =
          role.name === 'admin' || role.name === 'reviewer'
            ? '/review/stage1'
            : '/stage1/dataentry';

        setTimeout(() => {
          navigate(path);
        }, 1000);

        return {
          status: 'success',
          message: '',
        };
      }
    } catch (errors) {
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    try {
      await apiSignOut();
      handleSignOut();
    } catch (errors) {
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
