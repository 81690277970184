import React, { useEffect } from 'react';
import { useConfig } from '../ConfigProvider';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const Sorter = ({ sort, setSort, column, sorting }) => {
  const { themeColor, primaryColorLevel } = useConfig();
  useEffect(() => {
    if (sorting) {
      setSort({
        orderBy: column?.id,
        sort:
          column?.isSortedDesc === false
            ? 'asc'
            : column?.isSortedDesc === true
            ? 'desc'
            : undefined,
      });
    }
  }, [sort]);

  const color = `text-${themeColor}-${primaryColorLevel}`;

  const renderSort = () => {
    if (typeof sort !== 'boolean') {
      return <FaSort />;
    }
    return sort ? <FaSortDown className={color} /> : <FaSortUp className={color} />;
  };

  return <div className="inline-flex">{renderSort()}</div>;
};

export default Sorter;
